// @flow
import React from 'react'

import { EditableContent, useBlock } from '@shift-marketing/shift-one-website-editor'

import Button, { ButtonProps } from 'components/Button'

import propertiesSchema from './properties.schema.json'

type Props = ButtonProps & {
    blockId: string,
    label: string,
}

export default function SubmitBlock (props: Props) {
    const { blockId, style, ...buttonProps } = props
    let defaultProps = React.useMemo(() => ({ label: props.label }), [props.label])

    const { properties, editMode, handlePropertyChange } = useBlock(blockId, propertiesSchema, defaultProps)

    return (
        <Button isSubmit color="primary" {...buttonProps} style={style} blockId={blockId}>
            <EditableContent
                editMode={editMode}
                content={properties.label}
                property="label"
                onChange={handlePropertyChange}
                inline
            />
        </Button>
    )
}
