// @flow
import * as React from 'react'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'

import formatPhoneNumber from 'util/formatPhoneNumber'

import styles from './styles.module.scss'

export default function FooterBar () {
    const { state: { campaign } } = React.useContext(Store)

    const address = campaign.client.address

    const formattedAddress = [
        ((address.line1 || '') + ' ' + (address.line2 || '')).trim(),
        address.city,
        address.province,
        address.postalCode
    ].join(' ')
    const formattedPhone = campaign.client.phone
        ? ` | Ph: <span class="${styles.contain}">${formatPhoneNumber(campaign.client.phone)}</span>` : ''
    const formattedEmail = campaign.client.email
        ? ` | Em: <span class="${styles.contain}">${campaign.client.email}</span>` : ''
    const formattedWebsite = campaign.client.website ?
        `<a href='${campaign.client.website}' target="_blank" rel="noopener noreferrer">${campaign.client.website}</a>` : ''

    return (
        <div
            className={styles.text}
        >
            <TextBlock
                blockId={'footer.info'}
                defaultContent={`
                    <p>
                        ${formattedAddress}
                            
                        ${formattedPhone}
                        
                        ${formattedEmail}
                        
                        ${formattedWebsite}
                    </p>
                `}
                onAnchorClick={createClickActivity}
            />
        </div>
    )
}
