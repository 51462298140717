// @flow
import React from 'react'
import cn from 'classnames'
import { useBlock } from '@shift-marketing/shift-one-website-editor'

import Modal from 'components/Modal'
import VideoPlayer from 'components/VideoPlayer'

import styles from './styles.module.scss'

import schema from './properties.schema.json'

export default function VideoPlayButton () {
    const { properties, ref, editMode } = useBlock('landing.header.video.button', schema)

    const videoPlayer = React.useRef()
    const [ isModalOpened, setIsModalOpened ] = React.useState(false)

    const handlePlayVideo = React.useCallback(() => {
        setIsModalOpened(true)
    }, [])

    const playVideo = React.useCallback(() => {
        videoPlayer.current.play()
    }, [videoPlayer])

    if (!editMode && !properties.videoUrl) {
        return null
    }

    return (<>
        <svg ref={ref} onClick={handlePlayVideo} className={cn(styles.playButton, { [styles.isDisabled]: !properties.videoUrl })} width="76px" height="75px" viewBox="0 0 76 75" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <ellipse fill={properties.buttonColor} cx="38" cy="37.5" rx="38" ry="37.5"></ellipse>
                    <path d="M43.7511554,26.1739691 L56.3635009,49.0338453 C56.8970934,50.0009817 56.5456366,51.2175629 55.5785002,51.7511554 C55.2826408,51.9143881 54.9502474,52 54.6123455,52 L29.3876545,52 C28.283085,52 27.3876545,51.1045695 27.3876545,50 C27.3876545,49.6620981 27.4732664,49.3297047 27.6364991,49.0338453 L40.2488446,26.1739691 C40.7824371,25.2068327 41.9990183,24.8553759 42.9661547,25.3889684 C43.2966567,25.5713144 43.5688094,25.843467 43.7511554,26.1739691 Z" fill={properties.iconColor} transform="translate(42.000000, 37.500000) rotate(-270.000000) translate(-42.000000, -37.500000) "></path>
                </g>
            </g>
        </svg>

        <Modal afterOpen={playVideo} isOpen={isModalOpened} onCancel={() => setIsModalOpened(false)}>
            <VideoPlayer url={properties.videoUrl} ref={videoPlayer}/>
        </Modal>
    </>)
}
